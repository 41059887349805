@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "FuturaBold";
  src: local("FuturaBold"), url("./assets/fonts/FuturaBold.ttf") format("truetype");
}

@font-face {
  font-family: "FuturaExtraBlack";
  src: local("FuturaExtraBlack"), url("./assets/fonts/FuturaExtraBlack.ttf") format("truetype");
}

@font-face {
  font-family: "FuturaMedium";
  src: local("FuturaMedium"), url("./assets/fonts/FuturaMedium.ttf") format("truetype");
}

.stroke {
  -webkit-text-fill-color: #ece952;
  -webkit-text-stroke-width: 3px;
  -webkit-text-stroke-color: #2f2f2f;
}
